import { initFacebookSdk } from '@utils/scriptSdkUtil';
import dynamic from 'next/dynamic';
import React, {useEffect} from 'react';
import { useSelector } from 'react-redux';
const PureIndex = dynamic(() => import('./PureIndex'), {
  ssr: false
});

const LoginPopup = () => {
  const visible = useSelector((state) => state.app.auth.isShowPopupLogin);
  
  useEffect(() => {
    if (visible) {
      initFacebookSdk();
    }
  }, [visible]);

  if (visible) {
    return <PureIndex />;
  }
  return null;
};

export default LoginPopup;
