import { FACEBOOK_APP_ID } from 'src/constants';

const retry = (callback) => {
  // return "RETRY" to retry
  const stepTime = 100;
  const maxTime = 5000;
  let totalTime = 0;
  let result = callback();
  const intervalCheck = setInterval(function () {
    if (totalTime > maxTime) {
      clearInterval(intervalCheck);
    }
    if (result !== "RETRY") {
      clearInterval(intervalCheck);
    }
    // retry
    if (result === "RETRY") {
      result = callback();
    }
    totalTime += stepTime;
  }, stepTime);
}

export const initFacebookSdk = () => {
  return new Promise((resolve) => {
    console.info("init facebook sdk")
    if (window.initFacebookSdk) {
      console.info("init facebook sdk: was loaded successfully")
      resolve();
      return;
    }
    console.info("init facebook sdk: loading")
    window.fbAsyncInit = () => {
      // eslint-disable-next-line no-undef
      FB.init({
        appId: FACEBOOK_APP_ID,
        cookie: true, // enable cookies to allow the server to access the session
        xfbml: true, // parse social plugins on this page
        version: 'v5.0',
      });
      retry(() => {
        if (!window.FB) {
          return "RETRY";
        }
        window.initFacebookSdk = true;
        console.info("init facebook sdk: successfully", window.FB)
        resolve();
      })
    };

    // Load the SDK asynchronously
    (function (d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) return;
      js = d.createElement(s);
      js.id = id;
      js.src = 'https://connect.facebook.net/en_US/sdk/xfbml.customerchat.js';
      fjs.parentNode.insertBefore(js, fjs);
    })(document, 'script', 'facebook-jssdk');
  });
};
